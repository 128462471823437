



















import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import KuspitAccountLinkingModalViewModel from '@/vue-app/view-models/components/contract-savings/kuspit-account-linking/kuspit-account-linking-modal-view-model';

@Component({
  name: 'KuspitAccountLinking',
  components: {
    KuspitAccountLinkingInit: () => import('@/vue-app/components/contract-savings/kuspit-account-linking/kuspitAccountLinkingInit.vue'),
    KuspitAccountLinkingData: () => import('@/vue-app/components/contract-savings/kuspit-account-linking/kuspitAccountLinkingData.vue'),
    KuspitAccountLinkingProcess: () => import('@/vue-app/components/contract-savings/kuspit-account-linking/kuspitAccountLinkingProcess.vue'),
  },
})
export default class KuspitAccountLinkingModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  kuspit_modal_view_model = Vue.observable(
    new KuspitAccountLinkingModalViewModel(),
  );

  endProcess() {
    this.synced_is_open = false;
    this.kuspit_modal_view_model.endProcess();
  }

  endSuccessProcess() {
    this.$router.go(0);
  }
}

export default class KuspitAccountLinkingModalViewModel {
  private readonly STEPS = [
    'KuspitAccountLinkingInit',
    'KuspitAccountLinkingData',
    'KuspitAccountLinkingProcess',
  ];

  current_step = 0;

  current_component = this.STEPS[this.current_step];

  nextStep = () => {
    if (this.current_step < this.STEPS.length) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess= () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
  }
}
